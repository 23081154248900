<template>
  <el-form ref="searchForm"   label-width="240" :inline="true">
    
    <el-form-item label="选择项目:" prop="name">
    <el-select v-model="article" placeholder="请选择" style="width:240px" clearable>
      <el-option v-for="data in optionsype" :key="data.plot_id" :label="data.plot_name" :value="data.plot_id"></el-option>
    </el-select>
    </el-form-item>
    <el-form-item label="报事状态:" prop="status">
      <el-select v-model="status" placeholder="请选择" style="width:240px" clearable>
        <el-option  v-for="data in statuss" :key="data.value" :label="data.label" :value="data.value"></el-option>
      </el-select>
    </el-form-item>
	
    <el-form-item label="时间查询:">
      <el-date-picker
          v-model="selecttime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"
      >
      </el-date-picker>
    </el-form-item>
   <el-form-item label="人员查询:" prop="name"><el-input  v-model="tableDataName" placeholder="清输入" style="width:240px" clearable></el-input></el-form-item>
   <el-form-item label="报事类型:" prop="annotation_type">
   <el-select v-model="annotation_type" placeholder="请选择" style="width:240px" clearable>
     <el-option v-for="data in itemList" :key="data.id" :label="data.list_value" :value="data.id"></el-option>
   </el-select>
   </el-form-item>
	<el-form-item label="描述查询:" prop="name"><el-input  v-model="content" placeholder="清输入" style="width:240px" clearable></el-input></el-form-item>
	<el-form-item><el-button type="primary" @click="queryrepairs()" icon="el-icon-search" >搜索</el-button></el-form-item>
    <el-form-item><el-button type="danger" @click="reset()" plain>重置</el-button></el-form-item>
	<el-form-item><el-button type="primary" @click="exportExcel()" plain>导出</el-button></el-form-item>
  </el-form>
  <el-table :data="dataList" border>
    <el-table-column  type="selection"  align="center"  width="55"></el-table-column>
	<el-table-column prop="plot_name" label="所属项目"  width="88"></el-table-column> 
    <el-table-column prop="user_name" label="报事用户" ></el-table-column>   
    <el-table-column prop="real_name" label="真实姓名"  ></el-table-column>
	 <el-table-column prop="manage" label="用户类型" :formatter="formanage" ></el-table-column>
	 <el-table-column prop="reason" label="扫码点位名称"  ></el-table-column>
    <el-table-column prop="content" label="报事描述" :show-overflow-tooltip="true"></el-table-column>
	<el-table-column prop="list_value" label="报事类型" ></el-table-column>
    <el-table-column prop="annotation_reason" label="批注"  :show-overflow-tooltip="true"></el-table-column>
	<el-table-column prop="delContent" label="处理备注"  v-if="false"></el-table-column>	
	<el-table-column prop="status" label="报事状态" :formatter="forstatus"></el-table-column>
    <el-table-column prop="create_time" label="报事时间" :formatter="setCreateTime" width="160"></el-table-column>
    <el-table-column fixed="right" label="操作" width="200px" >
      <template v-slot="scope"  >
        <el-button  icon="el-icon-view"  @click="View(scope.row)" type="view" size="mini">查看</el-button>
		<el-button  icon="el-icon-delete" v-if="scope.row.is_del == '0'" @click="Deleteinfo(scope.row)" type="danger" size="mini">取消</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-config-provider :locale="locale">
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pagesize"
        layout="total,sizes,prev,pager,next,jmper"
        :total="total">
    </el-pagination>
  </el-config-provider>
   <rms_thing_export @closeExport="closerepairsView" v-if="exportDialogVisible"></rms_thing_export>
   <rep_repairs_view @closerepairsView="closerepairsView"  v-if="repairsViewDialogVisible"></rep_repairs_view>
</template>
<script>
import rep_repairs_view from './rms_thing_view.vue';
import rms_thing_export from './rms_thing_export.vue'
import { ElConfigProvider } from 'element-plus';
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
  name: 'ZhProvider',
  setup(){
    let locale = zhCn
    return{
      locale
    }
  },
  /*启动后触发该方法*/
  created() {
    this.handleClickQuery();
    this.getplotname();
	this.searchDictionaryTableList();
  },
  /*方法主体*/
  methods: {  
	  /*删除的方法*/
	  Deleteinfo:function(item){
	    this.$confirm('是否确认取消。', '提示', {
	      confirmButtonText: '确定',
	      cancelButtonText: '取消',
	      type: 'warning'
	    }).then(() => {
	      this.updeteclass(item);
	    }).catch(() => {});
	  },
	  updeteclass:function(item){
	        var self = this;
	        var data = {};
	        data.cmd = "delRepairsThingsById";
	        data.id=item.id,
	        data.is_del=1,
	        this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
	          self.$message("取消成功");
	          this.handleClickQuery();
	        }, function(data) {
	        }, self);
	  },
	  searchDictionaryTableList:function(data){
	  	var self = this;
	  	this.itemList = [];
	  	var req = {};
	  	req.cmd = "searchDictionaryTableList";
	  	this.$sknet.postJson(this.$skconf("brum.surl"), req, function(res) {
	  		if(res.datas.items && res.datas.items.length > 0){
	  			this.itemList= res.datas.items;
	  		}
	  	}, null, this);
	  },
    handleClickQuery:function(data){
		var self = this;
		this.dataList = [];
		var req = {};
		req.cmd = "searchRmsThingList";
		req.start=(this.currentPage-1) * this.pagesize;
		req.count=this.pagesize;
		req.plot_id =this.article;    //所属项目
		req.status =this.status;      //会员状态
		req.selecttime=this.selecttime; //查询时间
		req.synthesize_params=this.tableDataName;   //综合查询
		req.content = this.content;
		 req.annotation_type = this.annotation_type;
		this.$sknet.postJson(this.$skconf("brum.surl"), req, function(res) {
			if(res.datas.items && res.datas.items.length > 0){
				this.dataList= this.formatItems(res.datas.items);
			}
			this.total=res.datas.totalCount;
		}, null, this);
    },
	formatItems:function(items){
		var res = [];
		var tmp = {};
		var bd = 0;
		for(var i = 0; i <  items.length; i++){
			tmp = items[i];
			tmp.isOverTime = false;
			if(tmp.finish_time && tmp.finish_time != ""){
				bd = this.getDaysBetween(tmp.finish_time);
				if(bd >= 15){
					tmp.isOverTime = true;
				}
			}
			res.push(tmp);
		}
		return res;
	},
	getDaysBetween:function(dateString1){
	    var  startDate = Date.parse(dateString1);
	    var  endDate = new Date();
	    if (startDate>endDate){
	        return 0;
	    }
	    if (startDate==endDate){
	        return 0;
	    }
	    var days=(endDate - startDate)/(1*24*60*60*1000);
	    return  days;
	},
	setCreateTime:function(row,colum){
		return row.create_time.substr(0,19);
	},
    //条件查询的方法
    queryrepairs:function(){
      this.dataList = [];
      var req = {};
      req.cmd = "searchRmsThingList";
      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;
      req.plot_id =this.article;    //所属项目
      req.status =this.status;      //会员状态
      req.selecttime=this.selecttime; //查询时间
	 req.synthesize_params=this.tableDataName;   //综合查询
	 req.content = this.content;
	 req.annotation_type = this.annotation_type;
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
		if(res.datas.items && res.datas.items.length > 0){
			this.dataList= this.formatItems(res.datas.items);
		}
        this.total=res.datas.totalCount;
      }, null, this);
    },
    getplotname:function(){
      var self = this;
      self.options;
      var redata = {};
      redata.cmd = "getAllPmsName";
      redata.id=this.ids;
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
        this.optionsype=datas.datas.items;
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
    reset:function(){
      this.article='';    //所属项目
      this.status='';      //会员状态
      this.selecttime=[]; //查询时间
      this.handleClickQuery();
    },
    //关闭查看弹出框
    closerepairsView:function(){
      this.id = this.id;
      this.repairsViewDialogVisible = false;
	  this.exportDialogVisible =  false;
      this.handleClickQuery();
    },
    View: function(item) {
      this.id = item.id+"";
      this.repairsViewDialogVisible = true;
    },	
	exportExcel: function() {
	  this.exportDialogVisible = true;
	},
    /*分页代码*/
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleClickQuery();
      console.log(this.currentPage)  //点击第几页
    },
	formanage:function(row,colum){
	  const state={
	    0:'普通人员',
	    1:'服务人员',
	    2:'维修人员',
	    3:'工程经理',
		4:'项目管理人员'
	  }
	  return  state[row.manage];
	},
    forstatus:function(row,colum){
      const state={
		1:'进行中',
        2:'进行中',
        3:'待评价',
        4:'已完成',
		5:'已取消',
      }
      return state[row.status];
    }
  },
  data() {
    let self = this;
    return{
      dataList:'',
      repairsViewDialogVisible:false, //查看页面弹出的判断标识
	  exportDialogVisible:false,
      total:0,          //总页数
      pagesize:10,        //每页数量
      currentPage:1,    //初始页
      checkedGh: [],    //复选框默认值
      paramsid:'',    //单选框指向的id
      paramsrepair:'',//单选行的施工人员id
	  is_del_info:'0',	  
      statuss:[         //报修状态
        {
          value:'2',
          label:'进行中',
        },
        {
          value:'3',
          label:'待评价',
        },
        {
          value:'4',
          label:'已完成',
        },
		{
		  value:'5',
		  label:'已取消',
		}
      ],
	  selOrderStatus: -1, //选择的订单状态
      article:'',     //所属项目选项
      work_type:'',
      optionsype:'',  //所属项目下拉
      status:'',      //报修状态选项
      selecttime:[],   //时间查询的字段
      orderid:"",
      calloff:'',
      dialog_visible:false,
      ismanager:false,
	  tableDataName:"",
	  content:"",
	  annotation_type:""
    }
  },
  components:{
    rep_repairs_view,rms_thing_export,
    [ElConfigProvider.name]: ElConfigProvider
  }

}


</script>