<template>
  <el-dialog
      title="查看"
      v-model="performanceinfo"
      width="900px"
      height="200px"
      center append-to-body @close='closeDialog'>
    <div class="el-dialog-div">
     <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-height="100px" class="ruleForm">
	<el-row>
	  <el-form-item label="报修用户" prop="name" >
		<el-input  :readonly="true"  v-model="ruleForm.user_name" ></el-input>
	  </el-form-item>
	  <el-form-item label="真实姓名" prop="name">
		<el-input :readonly="true"  v-model="ruleForm.real_name"></el-input>
	  </el-form-item>
	  <el-form-item label="扫码点位" prop="name">
	  		<el-input :readonly="true"  v-model="ruleForm.reason"></el-input>
	  </el-form-item>
	  <el-form-item label="所属项目" prop="name">
		<el-input :readonly="true"  v-model="ruleForm.plot_name"></el-input>
	  </el-form-item>
	</el-row>

	
	  <el-steps :space="400" :active="active" align-center finish-status="success">
		<el-step :title="step1" :description="description1" />
		<el-step :title="step2" :description="description2" />
		<el-step :title="step3" :description="description3" />
		<el-step :title="step4" :description="description4" />
	  </el-steps>			
	

      <el-form-item label="报事描述" prop="name">		
        <el-input :readonly="true" type="textarea"  v-model="ruleForm.content"></el-input>
      </el-form-item>

	   <el-row v-show="status > 1">
	  <el-form-item label="报事类型" prop="name">
	    <el-input :readonly="true"  v-model="ruleForm.list_value"></el-input>
	  </el-form-item>
	  <el-form-item label="批注时间" prop="name">
	    <el-input :readonly="true" v-model="ruleForm.annotation_time" ></el-input>
	  </el-form-item>
	  <el-form-item label="批注人" prop="name">
	    <el-input :readonly="true" v-model="ruleForm.ann_real_name" ></el-input>
	  </el-form-item>
	   </el-row>
	   
	   <el-form-item label="批注内容" prop="name" v-show="status > 1">
	     <el-input :readonly="true" type="textarea" v-model="ruleForm.annotation_reason" ></el-input>
	   </el-form-item>
	   
	   <el-row v-show="status > 2">
	   <el-form-item label="处理时间" prop="name">
	     <el-input :readonly="true" v-model="ruleForm.del_time"></el-input>
	   </el-form-item>
	   <el-form-item label="处理人" prop="name">
	     <el-input :readonly="true" v-model="ruleForm.del_real_name"></el-input>
	   </el-form-item>
	   </el-row>
	   <el-form-item label="处理备注" prop="name" v-show="status > 2">
	     <el-input :readonly="true" type="textarea" v-model="ruleForm.delContent"></el-input>
	   </el-form-item>
	    
		<el-row v-show="status > 3">
      <el-form-item label="评价星数" prop="name">
        <el-input :readonly="true"  v-model="ruleForm.stars_nums"></el-input>
      </el-form-item>
      <el-form-item label="完成时间" prop="name">
        <el-input :readonly="true"  v-model="ruleForm.finish_time"></el-input>
      </el-form-item>	  
	  </el-row>
	  <el-form-item label="评价内容" prop="name" v-show="status > 3">
	    <el-input :readonly="true"  type="textarea" v-model="ruleForm.comment"></el-input>
	  </el-form-item>
     
	   <el-row>
      <el-form-item label="报事图片" prop="name">
		<el-image
		  border="11"
		  style="margin: 3px;  padding: 3px; width:100px;height:100px;border:solid 1px #6a7990; border-radius:10%"
		  v-for="item in srcListFirst"
		  v-bind:key="item"
		  hide-on-click-modal="true"
		  :src="item" 
		  :preview-src-list="srcListFirst">
		</el-image>
	  </el-form-item>
      <el-form-item label="处理图片" prop="name" v-show="status > 2">
		<el-image
		  border="11"
		  style="margin: 3px;  padding: 3px; width:100px;height:100px;border:solid 1px #6a7990; border-radius:10%"
		  v-for="item in srcList"
		  v-bind:key="item"
		  hide-on-click-modal="true"
		  :src="item" 
		  :preview-src-list="srcList">
		</el-image>
	  </el-form-item>	
	   </el-row>
    </el-form>
    <div class="clear"></div>
    </div>
    <div class="ucForm-group-ad">
      <div class="ucForm-group ucBtn-bg">
        <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog" ><i class="iImg"></i>返回</button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  created () {
    this.performanceinfo = true;
    this.clearinfo();
    this.handleClickQuery();
  },
  data(){
    let self=this;
    return{
      position:'top',
      ruleForm:{
        id:this.$parent.id,
      },
	  srcList:[],
	  srcListFirst:[],
	  status:0,
	  active:0,
	  step1:"",
	  step2:"",
	  step3:"",
	  step4:"",
	  description1:"",
	  description2:"",
	  description3:"",
	  description4:""
    }},
  methods: {
    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "searchRmsThingList";
      req.id=this.$parent.id;
      this.$sknet.postJson(this.$skconf("cla.surl"), req, function(res) {
        this.ruleForm=res.datas.items[0];
        var picture=this.ruleForm.picture;
        var maintain=this.ruleForm.delPicture;
		this.active = this.ruleForm.status;
		this.status = this.ruleForm.status;
        if(picture!=null){
          var picturearray=picture.split(',')
          for(let i=0;picturearray.length>i;i++){
			  this.srcListFirst.push(picturearray[i]);
          }
        }
        if(maintain!=null){
          var maintainarray=maintain.split(',')
          for(let i=0;maintainarray.length>i;i++){
            if(maintainarray[i]!=''&&picturearray!=null) {
			  this.srcList.push(maintainarray[i]);
            }
          }
        }
		
		var name = "";
		if(this.ruleForm.manage == "0"){
			name = "业主";
		}else if(this.ruleForm.manage == "1"){
			name = "服务人员";
		}else if(this.ruleForm.manage == "2"){
			name = "维修人员";
		}else if(this.ruleForm.manage == "3"){
			name = "工程经理";
		}else if(this.ruleForm.manage == "4"){
			name = "项目管理人员";
		}
						
		this.step1 = name +"报事（" +  this.ruleForm.real_name +"）" ;
		this.description1 = this.ruleForm.create_time;
		if(this.status > 1){
			if(this.ruleForm.manage == "0"){
				this.step2 = "经理已批注（" +  this.ruleForm.ann_real_name +"）" ;
			}else{
				this.step2 = name+"已批注（" +  this.ruleForm.ann_real_name +"）" ;
			}
			this.description2 = this.ruleForm.annotation_time;
		}else{
			this.step2 = "经理未批注" ;
			this.description2 = "";
		}
		
		if(this.status > 2){
			this.step3 = "指派人已处理（" +  this.ruleForm.del_real_name +"）" ;
			this.description3 = this.ruleForm.del_time;
		}else{		
			if(this.ruleForm.del_no_real_name != undefined){
				this.step3 = "指派人未处理（" +  this.ruleForm.del_no_real_name +"）" ;
			}else{
				this.step3 = "指派人未处理" ;
			}
			this.description3 = "";
		}
		
		if(this.status > 3){
			this.step4 = "报事人已评价（" +  this.ruleForm.real_name +"）" ;
			this.description4 = this.ruleForm.finish_time;
		}else{
			this.step4 = "报事人未评价" ;
			this.description4 = "";
		}

        
       }, null, this);
    },
    closeDialog: function(){
      this.clearinfo();
      this.$emit('closerepairsView'); //通知父组件改变。
    },
    clearinfo:function(){
    },
  },
  mounted(){
  },
  components: {
  }
}
</script>
<style scoped>
.el-dialog-div {
  height: 60vh;
  overflow: auto;
  Margin:5px;
}
</style>