<template>
	<el-dialog
	    title="导出"
	    v-model="exportAchievements"
	    width="30%"
	    center append-to-body @close='closeDialog'>
	  <el-form  label-width="100px" class="ruleForm">
	    <el-form-item label="选择项目:" prop="plot_name">
	      <el-select v-model="article" placeholder="请选择" style="width:400px" clearable>
	        <el-option v-for="data in optionstype" :key="data.plot_id" :label="data.plot_name" :value="data.plot_id"></el-option>
	      </el-select>
	    </el-form-item>
		<el-form-item label="开始日期" prop="beginDate" >
		  <el-date-picker v-model="beginDate" type="date" style="width:400px" value-format="YYYY-MM-DD" ></el-date-picker>
		</el-form-item>
		<el-form-item label="结束日期" prop="endDate" >
		  <el-date-picker v-model="endDate" type="date" style="width:400px" value-format="YYYY-MM-DD" ></el-date-picker>
		</el-form-item>
	    
	  </el-form>
	    <div class="ucForm-group-ad">
	      <div class="ucForm-group ucBtn-bg">
	        <button class="ucBtn ucBtn-ok ucBtn-blue" v-on:click="commit()"><i class="iImg"></i>确定</button>
	        <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog"><i class="iImg"></i>返回</button>
	      </div>
	    </div>
	    <div class="clear"></div>
	</el-dialog>
</template>

<script>
	export default {
		data(){
			return{
				article:'',
				optionstype:[],
				beginDate:'',
				endDate:'',
				ploat_id:'',
				ploat_name:'',
			}
		},
		created() {
			this.exportAchievements = true
			this.getplotname();
		},
		methods:{
			getplotname:function(){
			  var self = this;
			  var redata = {};
			  redata.cmd = "getAllPmsName";
			  this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
			    this.optionstype=datas.datas.items;
			  }, function(data) {
			    self.$message(data.reason);
			  }, self);
			},
			commit(){
				var req = {};
				req.cmd = "exportRmsThing";
				if(this.article != null && this.article != ""){
					req.plot_id = this.article;
				/* 	for(var i=0;i<this.optionstype.length;i++){
						if(req.plot_id==this.optionstype[i].plot_id){
							req.plot_name=this.optionstype[i].plot_name;
						}
					}
					console.log(this.plot_name) */
				}else{
					var plot_id = "";
					for(var i=0;i<this.optionstype.length;i++){					
						plot_id += this.optionstype[i].plot_id + ",";
					}
					if(plot_id == ""){
						this.$message("暂无权限，请分配项目");
						return
					}
					plot_id = plot_id.substring(0,plot_id.length-1);
					req.plot_id = plot_id;
				}
				if(this.beginDate!=null&&this.beginDate!=""){
					req.beginDate = this.beginDate+" 00:00:00";
				}else{
					this.$message("请输入开始日期")
					return
				}
				if(this.endDate!=null&&this.endDate!=""){
					req.endDate = this.endDate+" 23:59:59";
				}else{
					this.$message("请输入结束日期")
					return
				}
				this.$sknet.download(this.$skconf("met.surl"), req)
				this.closeDialog();
			},
			closeDialog: function(){
				this.exportAchievements = false
			    this.$emit('closeExport'); //通知父组件改变。
			},
		}
	}
</script>

<style>
</style>
